import * as React from "react"
import styled from "styled-components"
import back from "./home.png"
import logo from "./rs-logo.png"
import Search from "./Search"
import Listing from "./Listing"
import  "./searchResult.css"
import ResultListing from "./ResultListing"
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import  { useState, useEffect } from "react";
import Axios from "axios"
import { useTranslation, Trans } from "react-i18next";
import ListDetailsImages from "./ListDetailsImages"
import { BottomNavigation, Table, TableBody, TableHead } from "@mui/material"
import TableCell from '@mui/material/TableCell';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';


import TableRow from '@mui/material/TableRow';
import YouMayAlsoLikeList from "./YouMayAlsoLikeList"
import ListingDetailSlidingImages from "./ListingDetailSlidingImages"
import { Link, Navigate, useNavigate } from "react-router-dom"


const MainSection = styled.section`
  display: flex;
  flex-direction: column;
`
const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 810px;
  width: 100%;
  justify-content: center;
  color: #fff;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const HeroImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
const HeroContent = styled.div`
  position: relative;
  background: linear-gradient(90deg, rgba(107, 157, 197, 0.62) 0%, rgba(0, 0, 0, 0.89) 92%);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const HeroText = styled.div`
  display: flex;
  width: 1044px;
  max-width: 100%;
  flex-direction: column;
  margin: 147px 0 190px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`
const HeroHeading = styled.h1`
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  font: 400 58px/75px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    line-height: 58px;
  }
`
const HeroDescription = styled.p`
  margin-top: 19px;
  font: 300 20px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FullWidthImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 1024px;
  align-self: center;
  margin-top: 10px;
  max-width: 100%;
`
const FeaturedSection = styled.section`
  justify-content: center;
  align-items: center;
  background-color: #f4f3f3;
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding: 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const FeaturedContent = styled.div`
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
`
const FeaturedItem = styled.article`
  padding-bottom: 13px;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemContent = styled.div`
  background-color: #fff;
  padding: 40px 25px 10px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const FeaturedItemRow = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`
const FeaturedItemImage = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const FeaturedItemImageElement = styled.img`
  aspect-ratio: 2.38;
  object-fit: auto;
  object-position: center;
  width: 237px;
  max-width: 100%;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`
const FeaturedItemText = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 73%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const FeaturedItemTextContent = styled.div`
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #3f444b;
  width: 100%;
  padding: 57px 35px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`
const FeaturedItemHeading = styled.h2`
  color: #398abf;
  letter-spacing: 1.5px;
  font: 700 23px/43% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemDescription = styled.p`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 11px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemButton = styled.button`
  font-family: Roboto, sans-serif;
  justify-content: center;
  border-radius: 3px;
  border: 2px solid rgba(63, 68, 75, 1);
  align-self: start;
  margin-top: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  line-height: 100%;
  padding: 17px 27px;
  background: transparent;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`
const FeaturedItemDots = styled.div`
  align-self: center;
  display: flex;
  margin-top: 19px;
  gap: 12px;
`
const FeaturedItemDot = styled.div`
  border-radius: 4px;
  width: 8px;
  height: 8px;
  ${({ active }) => (active ? `background-color: #398abf;` : `background-color: #3a3f45;`)}
`
const SolutionsSection = styled.section`
  background-color: #fff;
  display: flex;
  margin-top: 71px;
  width: 100%;
  flex-direction: column;
  font-size: 50px;
  color: #3a3f45;
  font-weight: 400;
  text-align: center;
  line-height: 120%;
  padding: 4px 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
`
const SolutionsContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsBox = styled.div`
  justify-content: center;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
  padding: 32px;
  @media (max-width: 991px) {
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsHeading = styled.h2`
  font-family: Montserrat, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 23px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsItem = styled.div`
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: -52px;
  width: 100%;
  flex-direction: column;
  padding: 4px 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const SolutionsItemContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const SolutionsItemBox = styled.div`
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  width: 1140px;
  max-width: 100%;
  height: 414px;
`
const ActivitiesSection = styled.section`
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: -16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 45px 60px 26px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const ActivitiesBox = styled.div`
  padding-top: 44px;
  justify-content: flex-end;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
`
const ActivitiesHeading = styled.h2`
  color: #000;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  font: 400 50px/120% Montserrat, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
    white-space: initial;
  }
`
const ActivitiesItem = styled.div`
  background-color: #fff;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesItemContent = styled.div`
  background-color: #fff;
  display: flex;
  padding-bottom: 31px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesItemBox = styled.div`
  background-color: #fff;
  height: 739px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ListDetailsBody = (props) => {
  const [data,setData] = useState()
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categoryOptions,setCategoryOptions] = useState([])
  const [makeFilter, setMakeFilter] = useState("");
  const [makeOptions,setMakeOptions] = useState([])
  const [modelFilter, setModelFilter] = useState("");
  const [modelOptions,setModelOptions] = useState([])
  const [priceFilter, setPriceFilter] = useState("");
  const { t, i18n } = useTranslation();
  const [filteredProducts,setFilteredProducts] = useState([])
  const [attachments,setAttachments] = useState([])
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"
  const [AdsTitle,setAdsTitle] = useState()
  const [youMayAlsoLikedata,setYouMayAlsoLikedata] = useState()

  const [image,setImage] = useState()
  const [make,setMake] = useState()
  const [model,setModel] = useState()
  const [vintage,setVintage] = useState()
  const [listing,setListing] = useState()
  const [category,setCategory] = useState()
  const [description,setDescription] = useState()
  const [waferSize,setWaferSize] = useState()
  const [configuration,setConfiguration] = useState()
  const navigate = useNavigate()
  
  
   

  const resetFilters = () => {
    setCategoryFilter("");
    
    setMakeFilter("")
    setModelFilter("")
  };


  Axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "https://resochi.com"
  useEffect(() => {
    // if () {
    //send axios request here
    //console.log(state.requestCount)
    
    async function fetchResults() {
      try {
        const response = await Axios.get(`/api/ads/${props.id}`)
        // setState(draft => {
        //   draft.results = response.data
        //   draft.show = "results"
        // })
        const temp = response.data
        setData(temp)
        setAttachments(temp.externalFiles?temp.externalFiles.split("$"):null)
        setAdsTitle(temp.title?temp.title:temp.make.name.concat("/ ",temp.model.name) )
      } catch (e) {
        console.log("there is issue search")
      }
    }
    fetchResults()
    // return () => ourRequest.cancel()
    //}
  }, [])

  useEffect(() => {
    // if () {
    //send axios request here
    //console.log(state.requestCount)
    
    async function fetchResults() {
      try {
        const response = await Axios.get(`/api/ads/${props.id}`)
        // setState(draft => {
        //   draft.results = response.data
        //   draft.show = "results"
        // })
        const temp = response.data
        setData(temp)
        setAttachments(temp.externalFiles?temp.externalFiles.split("$"):null)
        setAdsTitle(temp.title?temp.title:temp.make.name.concat("/ ",temp.model.name) )
        setMake(temp.make.name)
        setModel(temp.model.name)
        setCategory(temp.category.name)
        setImage(temp.img)
        setVintage(temp.vintage)
        setWaferSize(temp.waferSize)
        

      } catch (e) {
        console.log("there is issue search")
      }
    }
    fetchResults()
    // return () => ourRequest.cancel()
    //}
  }, [props.id])

  function transformArray(arr,objectName) {
    if(objectName==="category")
    return Array.from(
      new Set(arr.map(item =>(
        item.category.name))))
    if(objectName==="make")
     return   Array.from(
          new Set(arr.map(item =>(
            item.make.name))))
    if(objectName==="model")
    return Array.from(
      new Set(arr.map(item =>(
        item.model.name))))
    return []
  }


  const handleDownload = (url,fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  const  advancedSearch = () =>{
    navigate("/searchResult")
  }
  useEffect(async () => {
    // if () {
    //send axios request here
    //console.log(state.requestCount)
    const ourRequest = Axios.CancelToken.source()
    // async function fetchResults() {
      try {
        const response = Axios.get(`/api/ads/findSameModel/${props.id}`).then(res=>
          // temp = response.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.feature===0)
        
          // tempFeatureData = response.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.model.name===data.model.name).filter(ads=>ads.id!=props.id);
          // setYouMayAlsoLikedata(res.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.model.name===data.model.name).filter(ads=>ads.id!=props.id))
          setYouMayAlsoLikedata(res.data)

          )
        // setState(draft => {
        //   draft.results = response.data
        //   draft.show = "results"
        // })
        
      } catch (e) {
        console.log("there is issue search")
      }
    // }
    // fetchResults()
    // return () => ourRequest.cancel()
    //}
  }, [props.id])

  // useEffect(() => {
  //   // if () {
  //   //send axios request here
  //   //console.log(state.requestCount)
  //   const ourRequest = Axios.CancelToken.source()
  //   async function fetchResults() {
  //     try {
  //       const response = await Axios.get(`/api/ads`)
  //       // setState(draft => {
  //       //   draft.results = response.data
  //       //   draft.show = "results"
  //       // })
  //       const temp = response.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.feature===0)
        
  //       const tempFeatureData = response.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.model.name===data.model.name).filter(ads=>ads.id!=props.id)
  //       setYouMayAlsoLikedata(tempFeatureData)
  //     } catch (e) {
  //       console.log("there is issue search")
  //     }
  //   }
  //   fetchResults()
  //   // return () => ourRequest.cancel()
  //   //}
  // }, [data])


  return (
    <MainSection>
      {" "}
      {/* <FullWidthImage src="" alt="Full width" />{" "} */}
      <FeaturedSection>
        {" "}
        <FeaturedContent>
        <div style={{padding:"1.5rem"}}><h3>Equipment Details </h3><div style={{textAlign:"right"}}>Not what you are looking for? Try <a style={{cursor:"pointer",color:"blue"}} onClick={advancedSearch}>Advanced Search</a></div>
      
      <div className="filters">
      {data && data.make && data.model && data.category && (<><div>
                <h4>{"SOLD"===data.status?"SOLD--":""}{AdsTitle}</h4>
                <p className="ads-text"><b>Listing#</b>: {data.listingNumber}</p>
                
                <p className="ads-text"><b>Make</b>: <Link to={`/model/${data.make?data.make.name:""}`} target="_blank">{data.make?data.make.name:""}</Link></p>
                <p className="ads-text"><b>Model</b>: <Link to={`/model/${data.model?data.model.name:""}`} target="_blank">{data.model?data.model.name:""}</Link></p>
                <p className="ads-text"><b>Category</b>: <Link to={`/model/${data.category?data.category.name:""}`} target="_blank">{data.category?data.category.name:""}</Link></p>
                <p className="ads-text"><b>Description</b>: {data.description}</p>
                {/* <p className="ads-text">Price: {data.price}</p> */}
                <p className="ads-text"><b>Vintage</b>: {data.vintage===0?"":data.vintage} </p>
                <p className="ads-text"><b>WaferSize</b>: {data.waferSize}</p>
                <p className="ads-text"><b>Configuration</b>: {data.configuration}</p>
                {/* <p className="ads-text">Country: {countryList().getLabel(data.country)}</p>
                <p className="ads-text">
                  <small className="text-muted">{data.location}</small>
                </p> */}
              </div>
              {attachments && (
              <div><b>Attachments</b>:
                <Table ><TableHead><TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Download</TableCell>
            
          </TableRow></TableHead><TableBody>
          {attachments && attachments.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.split("/").pop()}</TableCell>
              <TableCell style={{cursor:"pointer"}} onClick={() => handleDownload(row,row.split("/").pop())}><i className="bi bi-download"></i></TableCell>
            </TableRow>
          ))}
            </TableBody></Table>
              </div>)}
              </>)
              
              }
      
        {/* <button onClick={resetFilters} style={{marginLeft:"0.5rem",marginTop:"2rem"}}>Contact us</button> */}
      </div>
    <div className="results">
          {/* {data && (<ListDetailsImages featureProducts={data}/>)} */}
          {data && (<ListingDetailSlidingImages imageUrls={data.image?data.image.split("$"):null} itemId_={data.id} listingNumber_={data.listingNumber}/>)}
          </div>
          </div>
          <hr style={{marginTop:"60px",fontSize:"2rem"}}/> {/* Divider */}
          {/* // You may also like to see */}
              <div>
             {youMayAlsoLikedata && youMayAlsoLikedata.length>0 &&(   <YouMayAlsoLikeList featureProducts={youMayAlsoLikedata} />)}
              </div>

          {/* End of you may also like to see */}
        </FeaturedContent>{" "}
      </FeaturedSection>{" "}
      {/* <FullWidthImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/07fbed12dd6657845f5cb3beaf7bf920971669746ba7016b01dae5d2014f1b4e?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="Full width" />{" "}
      <SolutionsSection>
        {" "}
        <SolutionsContent>
          {" "}
          <SolutionsBox>
            {" "}
            <SolutionsHeading>OUR SOLUTIONS</SolutionsHeading>{" "}
          </SolutionsBox>{" "}
        </SolutionsContent>{" "}
      </SolutionsSection>{" "}
      <SolutionsItem>
        {" "}
        <SolutionsItemContent>
          {" "}
          <SolutionsItemBox />{" "}
        </SolutionsItemContent>{" "}
      </SolutionsItem>{" "}
      <SolutionsItem>
        {" "}
        <SolutionsItemContent>
          {" "}
          <SolutionsItemBox />{" "}
        </SolutionsItemContent>{" "}
      </SolutionsItem>{" "} */}
    </MainSection>
  )
}
export default ListDetailsBody
