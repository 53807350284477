import * as React from "react"
import styled from "styled-components"
import back from "./home.png"
import logo from "./rs-logo.png"
import Search from "./Search"
import Listing from "./Listing"
import RequestForm from "../raiseRequest/requestForm"
import ContactForm from "./ContactForm"

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
`
const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 810px;
  width: 100%;
  justify-content: center;
  color: #fff;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const HeroImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
const HeroContent = styled.div`
  position: relative;
  background: linear-gradient(90deg, rgba(107, 157, 197, 0.62) 0%, rgba(0, 0, 0, 0.89) 92%);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const HeroText = styled.div`
  display: flex;
  width: 1044px;
  max-width: 100%;
  flex-direction: column;
  margin: 147px 0 190px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`
const HeroHeading = styled.h1`
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  font: 400 58px/75px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    line-height: 58px;
  }
`
const HeroDescription = styled.p`
  margin-top: 19px;
  font: 300 20px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FullWidthImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 1024px;
  align-self: center;
  margin-top: 10px;
  max-width: 100%;
`
const FeaturedSection = styled.section`
  justify-content: center;
  align-items: center;
  background-color: #f4f3f3;
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding: 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const FeaturedContent = styled.div`
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  height: 750px;
`
const FeaturedItem = styled.article`
  padding-bottom: 13px;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemContent = styled.div`
  background-color: #fff;
  padding: 40px 25px 10px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const FeaturedItemRow = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`
const FeaturedItemImage = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const FeaturedItemImageElement = styled.img`
  aspect-ratio: 2.38;
  object-fit: auto;
  object-position: center;
  width: 237px;
  max-width: 100%;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`
const FeaturedItemText = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 73%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const FeaturedItemTextContent = styled.div`
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #3f444b;
  width: 100%;
  padding: 57px 35px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`
const FeaturedItemHeading = styled.h2`
  color: #398abf;
  letter-spacing: 1.5px;
  font: 700 23px/43% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemDescription = styled.p`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 11px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemButton = styled.button`
  font-family: Roboto, sans-serif;
  justify-content: center;
  border-radius: 3px;
  border: 2px solid rgba(63, 68, 75, 1);
  align-self: start;
  margin-top: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  line-height: 100%;
  padding: 17px 27px;
  background: transparent;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`
const FeaturedItemDots = styled.div`
  align-self: center;
  display: flex;
  margin-top: 19px;
  gap: 12px;
`
const FeaturedItemDot = styled.div`
  border-radius: 4px;
  width: 8px;
  height: 8px;
  ${({ active }) => (active ? `background-color: #398abf;` : `background-color: #3a3f45;`)}
`
const SolutionsSection = styled.section`
  background-color: #fff;
  display: flex;
  margin-top: 71px;
  width: 100%;
  flex-direction: column;
  font-size: 50px;
  color: #3a3f45;
  font-weight: 400;
  text-align: center;
  line-height: 120%;
  padding: 4px 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
`
const SolutionsContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsBox = styled.div`
  justify-content: center;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
  padding: 32px;
  @media (max-width: 991px) {
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsHeading = styled.h2`
  font-family: Montserrat, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 23px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsItem = styled.div`
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: -52px;
  width: 100%;
  flex-direction: column;
  padding: 4px 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const SolutionsItemContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const SolutionsItemBox = styled.div`
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  width: 1140px;
  max-width: 100%;
  height: 414px;
`
const ActivitiesSection = styled.section`
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: -16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 45px 60px 26px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const ActivitiesBox = styled.div`
  padding-top: 44px;
  justify-content: flex-end;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
`
const ActivitiesHeading = styled.h2`
  color: #000;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  font: 400 50px/120% Montserrat, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
    white-space: initial;
  }
`
const ActivitiesItem = styled.div`
  background-color: #fff;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesItemContent = styled.div`
  background-color: #fff;
  display: flex;
  padding-bottom: 31px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesItemBox = styled.div`
  background-color: #fff;
  height: 739px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const ContactBody = () => {
  return (
    <MainSection>
      {" "}
      {/* <FullWidthImage src="" alt="Full width" />{" "} */}
      <FeaturedSection>
        {" "}
        <FeaturedContent>
          <div style={{ marginTop: "30px" }}>
            <ContactForm />
          </div>
        </FeaturedContent>{" "}
      </FeaturedSection>{" "}
      {/* <FullWidthImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/07fbed12dd6657845f5cb3beaf7bf920971669746ba7016b01dae5d2014f1b4e?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="Full width" />{" "}
      <SolutionsSection>
        {" "}
        <SolutionsContent>
          {" "}
          <SolutionsBox>
            {" "}
            <SolutionsHeading>OUR SOLUTIONS</SolutionsHeading>{" "}
          </SolutionsBox>{" "}
        </SolutionsContent>{" "}
      </SolutionsSection>{" "}
      <SolutionsItem>
        {" "}
        <SolutionsItemContent>
          {" "}
          <SolutionsItemBox />{" "}
        </SolutionsItemContent>{" "}
      </SolutionsItem>{" "}
      <SolutionsItem>
        {" "}
        <SolutionsItemContent>
          {" "}
          <SolutionsItemBox />{" "}
        </SolutionsItemContent>{" "}
      </SolutionsItem>{" "} */}
    </MainSection>
  )
}
export default ContactBody
