import React, { useState, useCallback, useEffect,useRef } from "react"
import Paginations from "./Pagination"
//import data from "./data"
import GridExample from "./GridExample"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Axios from "axios"
import RequestForm from "../raiseRequest/requestForm"
import { useTranslation, Trans } from "react-i18next";
import FeatureProductList from "./FeatureProductList"
import { Navigate, useNavigate } from "react-router-dom"
import AppContext from "../../AppContext"
import './ImageWithSold.css';

  

const Listing = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState()
  
  const [numberOfRecord, setNumberOfRecord] = useState(0)
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  const [seen, setSeen] = useState(false)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const [featuredata, setFeaturedata] = useState()

  //resochi inventory
  const [data_ri,setData_ri] = useState()
  const [numberOfRecord_ri, setNumberOfRecord_ri] = useState(0)
  const [currentPage_ri, setCurrentPage_ri] = useState(1)


  const [currentListingNumber,setCurrentListingNumber] = useState()
  const [itemUrl,setItemUrl] = useState()

  // start of the 
  const bottomMarkerRef = useRef(null); // Ref for the bottom div

  // This state could control the visibility of your alert popup
  const [showAlert, setShowAlert] = useState(false);
  let LIMIT = 12
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       const entry = entries[0];
  //       if (entry.isIntersecting) {
  //         // Set state to show the alert when the bottom div is visible
  //         setShowAlert(true);
  //       }
  //     },
  //     {
  //       root: null, // observing for viewport
  //       rootMargin: '0px',
  //       threshold: 1.0, // Trigger when 100% of the observed entry is within the root's bounds.
  //     }
  //   );

  //   if (bottomMarkerRef.current) {
  //     observer.observe(bottomMarkerRef.current);
  //   }

  //   // Cleanup observer on component unmount
  //   return () => {
  //     if (bottomMarkerRef.current) {
  //       observer.unobserve(bottomMarkerRef.current);
  //     }
  //   };
  // }, []);
  // end of 

  function togglePop() {
    setSeen(!seen)
    setShowAlert(false)
  }
  Axios.defaults.baseURL = AppContext.apiUrl

  useEffect(() => {
    // if () {
    //send axios request here
    //console.log(state.requestCount)
    const ourRequest = Axios.CancelToken.source()
    async function fetchResults() {
      try {
        const response = await Axios.get(`/api/ads/front/feature`)
        // setState(draft => {
        //   draft.results = response.data
        //   draft.show = "results"
        // })
        
        const tempFeatureData = response.data
        setFeaturedata(tempFeatureData)
      } catch (e) {
        console.log("there is issue search")
      }
    }
    fetchResults()
    // return () => ourRequest.cancel()
    //}
  }, [])

  useEffect(() => {
    // if () {
    //send axios request here
    //console.log(state.requestCount)
    const ourRequest = Axios.CancelToken.source()
    async function fetchResults() {
      try {
        const response = await Axios.get(`/api/ads/front/${currentPage}/${LIMIT}`)
        // setState(draft => {
        //   draft.results = response.data
        //   draft.show = "results"
        // })
        const temp = response.data
        setData(temp)
        setNumberOfRecord(temp.totalElements)
        
      } catch (e) {
        console.log("there is issue search")
      }
    }
    fetchResults()
    // return () => ourRequest.cancel()
    //}
  }, [currentPage])

  useEffect(() => {
    // if () {
    //send axios request here
    //console.log(state.requestCount)
    const ourRequest = Axios.CancelToken.source()
    async function fetchResults() {
      try {
        const response = await Axios.get(`/api/ads/front_ri/${currentPage_ri}/${LIMIT}`)
        // setState(draft => {
        //   draft.results = response.data
        //   draft.show = "results"
        // })
        const temp = response.data
        setData_ri(temp)
        setNumberOfRecord_ri(temp.totalElements)
        
      } catch (e) {
        console.log("there is issue search")
      }
    }
    fetchResults()
    // return () => ourRequest.cancel()
    //}
  }, [currentPage_ri])

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault()
      setCurrentPage(page)
    },
    [setCurrentPage]
  )
  const onPageChanged_ri = useCallback(
    (event, page) => {
      event.preventDefault()
      setCurrentPage_ri(page)
    },
    [setCurrentPage_ri]
  )

  const goToDetails =(listId)=>{
    navigate(`/for-sale/${listId}`)
  }
  const togglePopForListNumberAndId = (listingNumber,itemId) => {
    setCurrentListingNumber(listingNumber); // Update the currentListingNumber state
    setItemUrl(fullDomainWithPort.concat("/for-sale/"+ itemId))
    setSeen(!seen); // Assuming 'seen' is your state to control popup visibility
  };

  const currentData = data?data.content:""
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"
  return (
    <main role="main" className="container">

      {featuredata && <FeatureProductList featureProducts={featuredata}/>}
      <h1 style={{backgroundColor:"#f4f3f3",marginBottom:"20px", marginTop:"60px",fontSize:"2rem"}}>{t("Resochi Inventory")}</h1>
      <div className="current-page">
        <div className="user">
          <div className="user__body">
            <Row xs={1} md={3} className="g-4">
              {data_ri && data_ri.content &&
                data_ri.content.map(item => (
                  <Col key={item.id}>
                    <Card>
                      <Card.Img 
                                  alt={`Resochi.com Inventory listing_image ${item.listingNumber}_${item.make.name}`}

                      onClick={()=>goToDetails(item.id)} variant="top" style={{ height: "350px", objectFit: "cover",cursor:"pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
                      {"SOLD"==item.status &&(<div className="overlay-text">SOLD</div>)}
                      <Card.Body>
                        <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>{item.make.name} / {item.model.name}</Card.Title>
                        <Card.Text onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>{item.description}</Card.Text>
                        <Card.Footer onClick={()=>togglePopForListNumberAndId(item.listingNumber,item.id)} style={{ backgroundColor: "rgb(41 72 121)", color: "white",cursor:"pointer",borderRadius: "var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={currentListingNumber} adsUrl={itemUrl} /> : null}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
            
          </div>
        </div>
        <div className="pagination-wrapper">{data_ri && numberOfRecord_ri > 0 && <Paginations totalRecords={numberOfRecord_ri} pageLimit={LIMIT} pageNeighbours={2} onPageChanged={onPageChanged_ri} currentPage={currentPage_ri} />}</div>
        <div ref={bottomMarkerRef} id="bottomDiv"></div>
        {showAlert && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            background: 'lightblue',
            padding: '20px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
           // Hide alert on click
        >
          <p onClick={
            togglePop
          }>Can not find what you are looking for? Contact us and we will find it for you.</p>
          <button onClick={()=>togglePopForListNumberAndId("null","0")} style={{width:"80%",backgroundColor:"rgb(41, 72, 121)", fontSize:"20px", color:"white",marginTop:"15px"}}>Contact us</button>
          <button id="x" onClick={() => setShowAlert(false)} >Close</button>
          {/* {seen ? <RequestForm toggle={togglePop}   /> : null} */}

        </div>
      )}
      </div>
      <h1 style={{backgroundColor:"#f4f3f3",marginBottom:"20px", marginTop:"60px",fontSize:"2rem"}}>{t("New Arrivals")}</h1>
      <div className="current-page">
        <div className="user">
          <div className="user__body">
            <Row xs={1} md={3} className="g-4">
              {data && data.content &&
                data.content.map(item => (
                  <Col key={item.id}>
                    <Card>
                      <Card.Img 
                                                        alt={`Resochi.com Inventory listing_image ${item.listingNumber}_${item.make.name}`}

                      onClick={()=>goToDetails(item.id)} variant="top" style={{ height: "350px", objectFit: "cover",cursor:"pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
                      {"SOLD"==item.status &&(<div className="overlay-text">SOLD</div>)}
                      <Card.Body>
                        <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>{item.make.name} / {item.model.name}</Card.Title>
                        <Card.Text onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>{item.description}</Card.Text>
                        <Card.Footer onClick={()=>togglePopForListNumberAndId(item.listingNumber,item.id)} style={{ backgroundColor: "rgb(41 72 121)", color: "white",cursor:"pointer",borderRadius: "var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={currentListingNumber} adsUrl={itemUrl} /> : null}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
            {/* <table id="table1">
              <thead>
                <tr>
                  <th>#</th>
                  <th>userId</th>
                  <th width="100%">title</th>
                  <th>status</th>
                </tr>
              </thead>
              {currentData.map(item => {
                return (
                  <tbody>
                    <tr>
                      <th scope="row">{item.id}</th>
                      <th scope="row">{item.userId}</th>
                      <td>{item.title}</td>
                      <td>{item.completed ? "completed" : "not completed"}</td>
                    </tr>
                  </tbody>
                )
              })}
            </table> */}
          </div>
        </div>
        <div className="pagination-wrapper">{data && numberOfRecord > 0 && <Paginations totalRecords={numberOfRecord} pageLimit={LIMIT} pageNeighbours={2} onPageChanged={onPageChanged} currentPage={currentPage} />}</div>
        <div ref={bottomMarkerRef} id="bottomDiv"></div>
        {showAlert && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            background: 'lightblue',
            padding: '20px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
           // Hide alert on click
        >
          <p onClick={
            togglePop
          }>Can not find what you are looking for? Contact us and we will find it for you.</p>
          <button onClick={()=>togglePopForListNumberAndId("null","0")} style={{width:"80%",backgroundColor:"rgb(41, 72, 121)", fontSize:"20px", color:"white",marginTop:"15px"}}>Contact us</button>
          <button id="x" onClick={() => setShowAlert(false)} >Close</button>
          {/* {seen ? <RequestForm toggle={togglePop}   /> : null} */}

        </div>
      )}
      </div>
    </main>
  )
}
export default Listing
